<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-18"></div>
	<div class="main-cont">
		<div id="blog-sec" class="mb-5">&nbsp;</div>
		<section class="blog-sec">
			<div class="card border-0 shadow-sm text-center" v-if="loading">
				<div class="card-body py-4">
					<loading-spinner color="white" text="ЗАГРУЗКА..." />
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center"  v-if="!loading && !blogitem">
				<div class="card-body py-4">
					<h1>ЗАПИСЬ НЕ НАЙДЕНА</h1>
					<div class="mt-4">
						<router-link :to="{path: '/blog', query: Object.assign({}, this.$route.query, {
							category: this.$route.query.category,
							page: this.$route.query.page||1,
						})}" class="btn btn-primary">Назад</router-link>
					</div>
				</div>
			</div>
			<div class="card border-0 shadow-sm" v-if="!loading && blogitem">
				<img :src="blogitem.poster.url" class="card-img-top" v-if="blogitem.poster" />
				<div class="card-body text-center">
					<div class="text-muted">{{blogitem.category_name}}</div>
					<h4 class="card-title">{{blogitem.title}}</h4>
					<small class="text-body-secondary">{{formatDate(blogitem.dateadd, 'DD.MM.YYYY')}}</small>
				</div>
				<div class="card-body border-1 border-top">
					<div v-highlight v-html="blogitem.content"></div>
				</div>
			</div>
		</section>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import LoadingSpinner from '@/components/LoadingSpinner';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Материал блога | byLex Portfolio'});
	},
	data: () => ({
		blogitem: {},
		
		loading: true,
		error: '',
	}),
	computed: {},
	methods: {
		range: lib.range,
		formatDate: lib.formatDate,
		
		async loadData(blogitem_id){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetBlogItem(blogitem_id);
			if(ret.success){
				this.blogitem = ret.item;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		getDateDuration(obj){
			return (obj.dateend != '0000-00-00 00:00:00' ?
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)
				:
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)
				);
		},
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	async mounted(){
		await this.loadData(this.$route.params.id);
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		LoadingSpinner,
	},
}
</script>