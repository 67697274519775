<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-18"></div>
	<div class="main-cont">
		<div id="project-sec" class="mb-5">&nbsp;</div>
		<section class="project-sec">
			<div class="card border-0 shadow-sm text-center" v-if="loading">
				<div class="card-body py-4">
					<loading-spinner color="white" text="ЗАГРУЗКА..." />
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center"  v-if="!loading && !work">
				<div class="card-body py-4">
					<h1>ЗАПИСЬ НЕ НАЙДЕНА</h1>
					<div class="mt-4">
						<router-link :to="{path: '/works', query: Object.assign({}, this.$route.query, {
							category: this.$route.query.category,
							page: this.$route.query.page||1,
						})}" class="btn btn-primary">Назад</router-link>
					</div>
				</div>
			</div>
			<div class="card border-0 shadow-sm" v-if="!loading && work">
				<div class="card-body">
					<div class="float-end" v-if="work.site_url">
						<a :href="work.site_url" target="_blank" class="btn btn-successy">Посмотреть</a>
					</div>
					<h4 class="mt-1 mb-4">{{work.title}}</h4>
					<div v-html="work.content"></div>
					<ul class="list-unstyled mt-4 mb-0">
						<li class="font-capitalize">
							<font-awesome-icon :icon="['fas', 'tag']" class="me-2" />
							<span>{{work.category_name}}</span>
						</li>
					</ul>
				</div>
				<div class="card-body border-1 border-top" v-if="work.images.length > 0">
					<lightgallery
						:settings="lightgallerySettings"
						:onInit="onLightGalleryInit"
						class="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-4"
					>
						<div class="col" v-for="(image, index) in work.images" :key="image.id">
							<div class="card border-1 h-100">
								<a :href="image.url" class="image">
									<div class="workitem card-img-cont">
										<div class="card-img-out">
											<div class="card-img-in">
												<img :src="image.url||'/assets/img/razrabs_poster.jpg'" />
											</div>
										</div>
									</div>
								</a>
							</div>
						</div>
					</lightgallery>
				</div>
			</div>
		</section>
	</div>
</template>

<style lang="css" scoped>
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-rotate.css';
@import 'lightgallery/css/lg-pager.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-transitions.css';
@import 'lightgallery/css/lg-video.css';
@import 'lightgallery/css/lg-zoom.css';
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import LoadingSpinner from '@/components/LoadingSpinner';

import Lightgallery from 'lightgallery/vue';
//import lgHash from 'lightgallery/plugins/hash';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
//import lgPager from 'lightgallery/plugins/pager';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Материал порфтолио | byLex Portfolio'});
	},
	data: () => ({
		work: {},
		
		lightgallerySettings: {
			speed: 500,
			plugins: [lgRotate, lgZoom, lgVideo, lgThumbnail],
			actualSize: false,
			showZoomInOutIcons: true,
			toggleThumb: true,
			allowMediaOverlap: true,
			selector: '.image',
		},
		lightGallery: null,
		
		loading: true,
		error: '',
	}),
	computed: {},
	methods: {
		range: lib.range,
		formatDate: lib.formatDate,
		
		getPoster(images){
			return images.find((el) => el.is_poster);
		},
		getImages(images){
			return images.filter((el) => !el.is_poster);
		},
		
		async loadData(work_id){
			this.loading = true;
			this.error = '';
			let ret = await this.appModel.doGetWork(work_id);
			if(ret.success){
				this.work = ret.item;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		// инициализация галереи
		onLightGalleryInit(detail){
			this.lightGallery = detail.instance;
		},
		
		getDateDuration(obj){
			return (obj.dateend != '0000-00-00 00:00:00' ?
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)
				:
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)
				);
		},
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	async mounted(){
		await this.loadData(this.$route.params.id);
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		LoadingSpinner,
		Lightgallery,
	},
}
</script>