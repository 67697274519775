import { library } from "@fortawesome/fontawesome-svg-core";

import {
	faTelegram as fabTelegram,
	faWhatsapp as fabWhatsapp,
	faVk as fabVk,
	faLinkedin as fabLinkedin,
	faSquareFacebook as fabSquareFacebook,
	faInstagram as fabInstagram,
	faYoutube as fabYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
	faChevronUp as fasChevronUp,
	faChevronLeft as fasChevronLeft,
	faChevronRight as fasChevronRight,
	faAnglesLeft as fasAnglesLeft,
	faAnglesRight as fasAnglesRight,
	faDownload as fasDownload,
	faEllipsisVertical as fasEllipsisVertical,
	faPhone as fasPhone,
	faBars as fasBars,
	faCode as fasCode,
	faBicycle as fasBicycle,
	faMusic as fasMusic,
	faGamepad as fasGamepad,
	faPlaneUp as fasPlaneUp,
	faCamera as fasCamera,
	faList as fasList,
	faTag as fasTag
} from "@fortawesome/free-solid-svg-icons";
import {
	faEnvelope as farEnvelope
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
	fabTelegram,
	fabWhatsapp,
	fabVk,
	fabLinkedin,
	fabSquareFacebook,
	fabInstagram,
	fabYoutube,
	fasChevronUp,
	fasChevronLeft,
	fasChevronRight,
	fasAnglesLeft,
	fasAnglesRight,
	fasDownload,
	fasEllipsisVertical,
	fasPhone,
	fasBars,
	fasCode,
	fasBicycle,
	fasMusic,
	fasGamepad,
	fasPlaneUp,
	fasCamera,
	fasList,
	fasTag,
	farEnvelope
);

export { FontAwesomeIcon };
