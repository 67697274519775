import "vue-code-highlight/themes/prism-okaidia.css";

//import 'bootstrap/dist/css/bootstrap.min.css';
import '@/scss/bootstrap.scss';
//import 'bootstrap/scss/bootstrap';
//import '@/assets/css/style.css';
//import '@/assets/css/bootstrap.min.css';
//import '@/assets/css/icons.min.css';
//import '@/assets/css/app.min.css';
import '@/assets/css/style.css';
import '@/main.scss';

import { createApp } from 'vue/dist/vue.esm-bundler';
import store from '@/store';
import App from '@/App.vue';
import router from '@/router';
import axios, { AxiosInstance } from 'axios';
import VueAxios from 'vue-axios';
import { UniversalSocialauth } from 'universal-social-auth';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { FontAwesomeIcon } from '@/assets/plugins/font-awesome';
import device from '@/assets/plugins/vue3-device-detector';
import VueCodeHighlight from 'vue-code-highlight';
import app_mixin from "@/mixin";
import lib from '@/lib';

//import '@popperjs/core';
import 'bootstrap';
//import '@/assets/js/date.format.js';

import 'prism-es6/components/prism-markup-templating';
import 'prism-es6/components/prism-php';
import 'prism-es6/components/prism-twig';
import 'prism-es6/components/prism-json';
import 'prism-es6/components/prism-sql';
import 'prism-es6/components/prism-yaml';
import 'prism-es6/components/prism-python';

import { Tooltip } from 'bootstrap';
new Tooltip(document.body, {
	selector: "[data-bs-toggle='tooltip']",
});

const options = {
	providers: {
		bylex: {
			clientId: '',
			redirectUri: '',
		},
	}
}
const Oauth = new UniversalSocialauth(axios, options);

const app = createApp(App);
const metaManager = createMetaManager();

window['appInstance'] = null;
window['appComponent'] = null;
window['storeInstance'] = null;

app.config.globalProperties.$Oauth = Oauth;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$store = store;
//app.config.productionTip = false;
//app.config.debug = true;
//app.config.devtools = true;
app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(store.state.app.i18n);
app.use(metaManager);
app.use(device);
app.use(VueCodeHighlight);
app.component('font-awesome-icon', FontAwesomeIcon);
lib.addMixin(app_mixin);
app.mount('#app');

window['lib'] = lib;
window['axios'] = axios;

window['appInstance'] = app;
window['storeInstance'] = store;
//window['routerInstance'] = router;

// Запускаем скрипт проверки авторизации
setTimeout(() => {
	//storeInstance.state.auth.SSI_step1();
}, 100);
