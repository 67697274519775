<template>
	<header>
		<nav class="container navbar navbar-expand-lg navbar-light fixed-top bg-white">
			<div class="container-fluid">
				<button class="navbar-toggler py-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
					<font-awesome-icon :icon="['fas', 'bars']" />
				</button>
				<router-link :to="{path: '/'}" class="navbar-brand">LexInZector</router-link>
				<div class="dropdown">
					<button class="navbar-toggler px-3 d-block d-lg-none" type="button" data-bs-toggle="dropdown" aria-expanded="false">
						<font-awesome-icon :icon="['fas', 'ellipsis-vertical']" class="navbar-toggler-icon" />
					</button>
					<ul class="dropdown-menu dropdown-menu-end">
						<li>
							<a class="dropdown-item py-2" href="/files/mikhalev_alexey_portfolio.pdf" download>
								<font-awesome-icon :icon="['fas', 'download']" class="text-danger" /> Скачать резюме
							</a>
						</li>
						<li>
							<a class="dropdown-item py-2" href="mailto:lexin.ucoz.ru@gmail.com">
								<font-awesome-icon :icon="['far', 'envelope']" class="text-success" /> Написать мне
							</a>
						</li>
						<li>
							<a class="dropdown-item py-2" href="https://t.me/lexinzector" target="_blank">
								<font-awesome-icon :icon="['fab', 'telegram']" class="text-info" /> Telegram
							</a>
						</li>
						<li>
							<a class="dropdown-item py-2" href="https://wa.me/77016838289" target="_blank">
								<font-awesome-icon :icon="['fab', 'whatsapp']" class="text-success" /> WhatsApp
							</a>
						</li>
						<li>
							<a class="dropdown-item py-2" href="tel:+77016838289">
								<font-awesome-icon :icon="['fas', 'phone']" class="text-primary" /> +7 701 68 38 28 9
							</a>
						</li>
						<li>
							<a class="dropdown-item py-2" href="tel:+77712563235">
								<font-awesome-icon :icon="['fas', 'phone']" class="text-primary" /> +7 771 256 32 35
							</a>
						</li>
					</ul>
				</div>
				<div class="collapse navbar-collapse" id="navbarCollapse">
					<ul class="navbar-nav justify-content-end w-100">
						<li class="nav-item">
							<router-link :to="{path: '/', hash: '#about_sec'}" class="nav-link" v-if="$router.currentRoute.value.name != 'home'">Обо мне</router-link>
							<a href="#about_sec" class="nav-link" :class="{'active': $router.currentRoute.value.hash == '#about_sec'}" v-else>Обо мне</a>
						</li>
						<li class="nav-item">
							<router-link :to="{path: '/', hash: '#skills_sec'}" class="nav-link" v-if="$router.currentRoute.value.name != 'home'">Навыки</router-link>
							<a href="#skills_sec" class="nav-link" :class="{'active': $router.currentRoute.value.hash == '#skills_sec'}" v-else>Навыки</a>
						</li>
						<li class="nav-item">
							<router-link :to="{path: '/', hash: '#experience_sec'}" class="nav-link" v-if="$router.currentRoute.value.name != 'home'">Опыт</router-link>
							<a href="#experience_sec" class="nav-link" :class="{'active': $router.currentRoute.value.hash == '#experience_sec'}" v-else>Опыт</a>
						</li>
						<li class="nav-item">
							<router-link :to="{path: '/', hash: '#education_sec'}" class="nav-link" v-if="$router.currentRoute.value.name != 'home'">Образование</router-link>
							<a href="#education_sec" class="nav-link" :class="{'active': $router.currentRoute.value.hash == '#education_sec'}" v-else>Образование</a>
						</li>
						<li class="nav-item">
							<router-link :to="{path: '/works'}" class="nav-link" :class="{'active': $router.currentRoute.value.name == 'works'}">Портфолио</router-link>
						</li>
						<li class="nav-item">
							<router-link :to="{path: '/blog'}" class="nav-link" :class="{'active': $router.currentRoute.value.name == 'blog'}">Блог</router-link>
						</li>
						<li class="nav-item" v-if="authModel.isAuth() && authModel.isAdmin()">
							<router-link :to="{path: '/admin'}" class="nav-link" :class="{'active': $router.currentRoute.value.name == 'admin'}">
								<span class="d-block d-sm-none">Админ-панель</span>
								<span class="d-none d-sm-block">А</span>
							</router-link>
						</li>
						<li class="nav-item dropdown d-none d-lg-block">
							<a class="nav-link ps-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
								<font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
							</a>
							<ul class="dropdown-menu dropdown-menu-end">
								<li>
									<a class="dropdown-item" href="/files/mikhalev_alexey_portfolio.pdf" download>
										<font-awesome-icon :icon="['fas', 'download']" class="text-danger" /> Скачать резюме
									</a>
								</li>
								<li>
									<a class="dropdown-item" href="mailto:lexin.ucoz.ru@gmail.com">
										<font-awesome-icon :icon="['far', 'envelope']" class="text-success" /> Написать мне
									</a>
								</li>
								<li>
									<a class="dropdown-item" href="https://t.me/lexinzector" target="_blank">
										<font-awesome-icon :icon="['fab', 'telegram']" class="text-info" /> Telegram
									</a>
								</li>
								<li>
									<a class="dropdown-item" href="https://wa.me/77016838289" target="_blank">
										<font-awesome-icon :icon="['fab', 'whatsapp']" class="text-success" /> WhatsApp
									</a>
								</li>
								<li>
									<a class="dropdown-item" href="tel:+77016838289">
										<font-awesome-icon :icon="['fas', 'phone']" class="text-primary" /> +7 701 68 38 28 9
									</a>
								</li>
								<li>
									<a class="dropdown-item" href="tel:+77712563235">
										<font-awesome-icon :icon="['fas', 'phone']" class="text-primary" /> +7 771 256 32 35
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
	<main class="container px-sm-2 px-md-0">
		<router-view/>
	</main>
	<footer-block />
	<button class="btn btn-lg btn-dark rounded-5 up-btn" @click="onScrollToTop" v-show="showBackToTop">
		<font-awesome-icon :icon="['fas', 'chevron-up']" />
	</button>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';

import FooterBlock from '@/components/FooterBlock';

export default {
	mixins: lib.mixins,
	data: () => ({
		showBackToTop: false,
	}),
	computed: {},
	methods: {
		onScrollToTop(){
			window.scrollTo({top: 0, behavior: 'smooth'});
		},
	},
	mounted(){
		// Показать/скрыть кнопку при прокрутке страницы
		window.addEventListener('scroll', () => {
			//if(window.pageYOffset > 300){
			if(window.scrollY > 300){
				this.showBackToTop = true;
			} else {
				this.showBackToTop = false;
			}
		});
	},
	created(){
		this.$http.interceptors.response.use(undefined, function(err){
			return new Promise(function(resolve, reject){
				if(err.status === 401 && err.config && !err.config.__isRetryRequest){
					this.$store.dispatch('logout');
				}
				throw err;
			});
		});
	},
	components: {
		FooterBlock,
	},
}
</script>
