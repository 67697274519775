<template>
	<header>
		<nav class="container navbar navbar-expand-lg navbar-light fixed-top bg-white">
			<div class="container-fluid">
				<button class="navbar-toggler py-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
					<font-awesome-icon :icon="['fas', 'bars']" />
				</button>
				<router-link :to="{path: '/admin'}" class="navbar-brand">AdminPanel</router-link>
				<div class="collapse navbar-collapse" id="navbarCollapse">
					<ul class="navbar-nav justify-content-end w-100">
						<li class="nav-item">
							<router-link :to="{path: '/'}" class="nav-link">
								<span class="d-block d-sm-none">Назад на сайт</span>
								<span class="d-none d-sm-block">На сайт</span>
							</router-link>
						</li>
						<li class="nav-item">
							<router-link :to="{path: '/admin/a'}" class="nav-link" :class="{'active': $router.currentRoute.value.name == 'admin-a'}">Admin A</router-link>
						</li>
						<li class="nav-item">
							<router-link :to="{path: '/admin/b'}" class="nav-link" :class="{'active': $router.currentRoute.value.name == 'admin-b'}">Admin B</router-link>
						</li>
						<li class="nav-item">
							<router-link :to="{path: '/logout'}" class="nav-link">Logout</router-link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
	<main class="container px-sm-2 px-md-0">
		<router-view/>
	</main>
	<button class="btn btn-lg btn-dark rounded-5 up-btn" @click="onScrollToTop" v-show="showBackToTop">
		<font-awesome-icon :icon="['fas', 'chevron-up']" />
	</button>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	data: () => ({
		showBackToTop: false,
	}),
	computed: {},
	methods: {
		onScrollToTop(){
			window.scrollTo({top: 0, behavior: 'smooth'});
		},
	},
	mounted(){
		// Показать/скрыть кнопку при прокрутке страницы
		window.addEventListener('scroll', () => {
			//if(window.pageYOffset > 300){
			if(window.scrollY > 300){
				this.showBackToTop = true;
			} else {
				this.showBackToTop = false;
			}
		});
	},
	created(){
		this.$http.interceptors.response.use(undefined, function(err){
			return new Promise(function(resolve, reject){
				if(err.status === 401 && err.config && !err.config.__isRetryRequest){
					this.$store.dispatch('logout');
				}
				throw err;
			});
		});
	},
	components: {},
}
</script>
