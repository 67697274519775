<template>
	<div class="bg-struct bg-struct-details bg-img bg-img-18"></div>
	<div class="main-cont">
		<div id="portfolio_sec" class="mb-5">&nbsp;</div>
		<section class="portfolio-sec">
			<div class="d-flex justify-content-between mb-3">
				<h4 style="z-index: 1;">Портфолио</h4>
				<div class="d-none d-lg-block">
					<div class="btn-group" role="group">
						<button @click="onSelCategory()" class="btn btn-dark" :class="{'active': category == null}">ВСЕ</button>
						<button @click="onSelCategory(workcat.id)" class="btn btn-dark" :class="{'active': category == workcat.id}" v-for="workcat in worksCategories" :key="workcat.id">{{workcat.name}}</button>
					</div>
				</div>
				<div class="d-block d-lg-none">
					<div class="dropdown">
						<button class="btn btn-dark px-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
							<font-awesome-icon :icon="['fas', 'list']" />
						</button>
						<ul class="dropdown-menu dropdown-menu-end">
							<li>
								<button @click="onSelCategory()" class="dropdown-item py-2 active">Все работы</button>
							</li>
							<li v-for="workcat in worksCategories">
								<button @click="onSelCategory(workcat.id)" class="dropdown-item py-2" :key="workcat.id">{{workcat.name}}</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center" v-if="loading">
				<div class="card-body py-4">
					<loading-spinner color="white" text="ЗАГРУЗКА..." />
				</div>
			</div>
			<div class="card border-0 shadow-sm text-center"  v-if="!loading && !works">
				<div class="card-body py-4">
					<h1>ЗАПИСЕЙ ПОКА НЕТ</h1>
				</div>
			</div>
			<div v-if="!loading && works">
				<div class="gallery-wrap">
					<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
						<div class="col" v-for="work in works" :key="work.id">
							<router-link :to="{path: '/works/'+work.id, query: Object.assign({}, this.$route.query, {
								category: this.category,
								page: this.page,
							})}" class="card border-0 shadow-sm text-decoration-none h-100">
								<div class="card-img-cont">
									<div class="card-img-out">
										<div class="card-img-in">
											<img :src="getPoster(work.images).url||'/assets/img/no-image.svg'" />
										</div>
									</div>
								</div>
								<div class="card-body">
									<h5 class="card-title">{{work.title}}</h5>
								</div>
							</router-link>
						</div>
					</div>
				</div>
				<div class="text-center mt-4">
					<div v-if="$device.isMobile">
						<span @click="gotoPage(1)" class="btn btn-white pull-left m-r-xs" :class="{'disabled': page == 1}">
							<font-awesome-icon :icon="['fas', 'angles-left']" />
						</span>
						<span @click="gotoPage(page - 1)" class="btn btn-white pull-left" :class="{'disabled': page == 1}">
							<font-awesome-icon :icon="['fas', 'chevron-left']" />
						</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white pull-right m-l-xs" :class="{'disabled': page == getPagesCount()}">
							<font-awesome-icon :icon="['fas', 'angles-right']" />
						</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white pull-right" :class="{'disabled': page == getPagesCount()}">
							<font-awesome-icon :icon="['fas', 'chevron-right']" />
						</span>
						<span class="btn btn-white">{{page}} из {{getPagesCount()}}</span>
					</div>
					<div class="btn-group" v-else>
						<span @click="gotoPage(page - 1)" class="btn btn-white" :class="{'disabled': page == 1}">
							<font-awesome-icon :icon="['fas', 'chevron-left']" />
						</span>
						<span @click="gotoPage(1)" class="btn btn-white" :class="{'active': page == 1}">1</span>
						<span class="btn btn-white disabled" v-if="page - paginationAlpha > 2">...</span>
						<span @click="gotoPage(i)" class="btn btn-white" :class="{'active': page == i}" v-for="i in range(paginationA1, paginationA2)" :key="i" v-if="paginationA2 >= paginationA1">{{i}}</span>
						<span class="btn btn-white disabled" v-if="page + paginationAlpha < getPagesCount() - 1">...</span>
						<span @click="gotoPage(getPagesCount())" class="btn btn-white" :class="{'active': page == getPagesCount()}" v-if="getPagesCount() > 1">{{getPagesCount()}}</span>
						<span @click="gotoPage(page + 1)" class="btn btn-white" :class="{'disabled': page == getPagesCount()}">
							<font-awesome-icon :icon="['fas', 'chevron-right']" />
						</span>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import LoadingSpinner from '@/components/LoadingSpinner';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Портфолио | byLex Portfolio'});
	},
	data: () => ({
		category: null,
		
		worksCategories: [],
		works: [],
		worksCount: 0,
		
		page: 1,
		limit: 9,
		loading: true,
		error: '',
		
		paginationAlpha: 3,
	}),
	computed: {
		paginationA1(){
			return Math.max(this.page - this.paginationAlpha, 2);
		},
		paginationA2(){
			return Math.min(this.page + this.paginationAlpha, this.getPagesCount() - 1);
		},
	},
	methods: {
		range: lib.range,
		formatDate: lib.formatDate,
		
		getPoster(images){
			return images.find((el) => el.is_poster);
		},
		getImages(images){
			return images.filter((el) => !el.is_poster);
		},
		
		async loadWorksCategories(){
			this.error = '';
			let ret = await this.appModel.doGetWorksCategories();
			if(ret.success){
				this.worksCategories = ret.list;
			} else {
				this.error = ret.message;
			}
		},
		async loadData(){
			this.loading = true;
			this.error = '';
			this.$router.push({query: Object.assign({}, this.$route.query, {
				category: this.category,
				page: this.page,
			})});
			let params = {
				start: (this.page - 1) * this.limit,
				limit: this.limit,
			};
			if(this.category){
				params.category_id = this.category;
			}
			let ret = await this.appModel.doGetWorks(params);
			if(ret.success){
				this.works = ret.list;
				this.worksCount = ret.list_count;
			} else {
				this.error = ret.message;
			}
			this.loading = false;
		},
		
		onSelCategory(category_id = null){
			this.category = category_id;
			this.loadData();
			window.scrollTo(0, 0);
		},
		gotoPage(page){
			if(page >= 1 && page <= this.getPagesCount() && page != this.page){
				this.page = page;
				this.loadData();
				window.scrollTo(0, 0);
			}
		},
		// получение количества страниц
		getPagesCount(){
			return Math.ceil(this.worksCount / this.limit);
		},
		
		getDateDuration(obj){
			return (obj.dateend != '0000-00-00 00:00:00' ?
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(obj.dateend, 'YYYY-MM-DD')).days + 1)
				:
					(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)+
					' '+
					lib.decl1(lib.dateDiff(this.formatDate(obj.dateadd, 'YYYY-MM-DD'), this.formatDate(new Date().toUTCString(), 'YYYY-MM-DD')).days + 1)
				);
		},
		number_format(value){
			return lib.number_format(value, 0, '.', ' ');
		},
	},
	async mounted(){
		if(this.$route.query.category) this.category = this.$route.query.category;
		if(this.$route.query.page) this.page = parseInt(this.$route.query.page || 1);
		
		this.loading = true;
		await this.loadWorksCategories();
		await this.loadData();
		this.loading = false;
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		LoadingSpinner,
	},
}
</script>