<template>
	<!--Footer Sec-->
	<footer class="footer-sec text-center">
		<p class="mt-4 mb-0 text-body-tertiary">Copyright &copy; 2002-{{currentYear}}, LexInZector</p>
		<div class="mb-3">
			<a :href="sl.link" target="_blank" class="btn btn-lg btn-link text-body-secondary text-decoration-none p-1" data-bs-toggle="tooltip" data-bs-placement="top" :title="sl.title" v-for="sl in appModel.socialLinks">
				<font-awesome-icon :icon="['fab', sl.icon]" />
			</a>
		</div>
	</footer>
	<!--Footer Sec-->
</template>

<style lang="scss" scoped>

</style>

<script>
import lib from '@/lib';

export default {
	mixins: lib.mixins,
	computed: {
		currentYear(){
			return this.formatDate(new Date().toUTCString(), 'YYYY');
		},
	},
	methods: {
		formatDate: lib.formatDate,
	},
	components: {},
}
</script>